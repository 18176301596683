import { BaseController } from './BaseController'
import { components } from 'Types/api'

export type CollegeCapacityAssignments = components['schemas']['CollegeCapacityAssignments']
export type CollegeCapacityAssignment = components['schemas']['CollegeCapacityAssignment']
export type CapacityAssignments = components['schemas']['CapacityAssignments']
export type Capacity = components['schemas']['CapacityAssignment']
export type CollegeDepartmentsCapacity = components['schemas']['CollegeDepartmentsCapacity']
export type DepartmentCapacity = components['schemas']['DepartmentCapacity']
export type StudentCapacities = components['schemas']['StudentCapacities']
export type StudentCapacity = components['schemas']['StudentCapacity']
export type GraphData = components['schemas']['GraphRecord']

export class CapacityController extends BaseController {
  readonly url_capacity_colleges: string
  readonly url_capacity_departments: string
  readonly url_capacity_students: string

  constructor () {
    super()

    this.url_capacity_colleges = `${this.apiHost}/${this.apiPath}/capacity/colleges`
    this.url_capacity_departments = `${this.apiHost}/${this.apiPath}/capacity/departments`
    this.url_capacity_students = `${this.apiHost}/${this.apiPath}/capacity/student`
  }

  checkStatusCode (status: number): boolean {
    return status >= 200 && status < 300
  }

  public async getCollegeCapacities (year?: string): Promise<CollegeCapacityAssignments> {
    const response = await this.useFetch('GET', 'getCollegeCapacities', `${this.url_capacity_colleges}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getDepartmentCapacity (departmentId: string, year: string): Promise<CapacityAssignments> {
    const response = await this.useFetch('GET', 'getCollegeCapacity', `${this.url_capacity_departments}/${departmentId}?yearId=${year}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getStudentCapacity (departmentId: string, startDate: string, endDate: string, exemptFee: boolean = false, exemptCapacity: boolean = false, exemptMonthLimit: boolean = false, applicationId?: string): Promise<StudentCapacities> {
    const query = [
      `startDate=${startDate}`,
      `endDate=${endDate}`,
      `exemptFee=${exemptFee}`,
      `exemptCapacity=${exemptCapacity}`,
      `exemptMonthLimit=${exemptMonthLimit}`,
      (applicationId !== '' && applicationId !== undefined) ? `applicationId=${applicationId}` : null
    ].filter((filter) => filter !== null).join('&')

    const response = await this.useFetch('GET', 'getStudentCapacity', `${this.url_capacity_students}/${departmentId}?${query}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async getCollegeCapacity (collegeId: string, year: string): Promise<CollegeDepartmentsCapacity> {
    const response = await this.useFetch('GET', 'getCollegeCapacities', `${this.url_capacity_colleges}/${collegeId}?yearId=${year}`)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }

  public async postCollegeCapacity (collegeId: string, body: CollegeDepartmentsCapacity): Promise<CollegeDepartmentsCapacity> {
    const response = await this.useFetch('POST', 'postApplication', `${this.url_capacity_colleges}/${collegeId}`, body)

    if (this.checkStatusCode(response.status)) {
      const data = response.json()
      return data
    }
    const text = await response.text()
    return await Promise.reject(text)
  }
}
