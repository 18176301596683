const apiConfigs = {
  "regional": {
    "apiHost": "https://api.mit.edu",
    "paths": {
      "apiPathDigitalId": "digital-id-v1",
      "apiPathVisitingStudent": "visiting-student-v1"
    }
  },
  "global": {
    "apiHost": "https://global.api.mit.edu",
    "paths": {
      "apiPathDigitalId": "digital-id/v1",
      "apiPathVisitingStudent": "visiting-student/v1"
    }
  }
};
export default apiConfigs;